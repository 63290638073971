import { HeadingSm } from '/features/buildingBlocks/Heading'
import { PortableTextCard } from '/features/buildingBlocks/PortableText'
import { routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { Icon } from '/features/buildingBlocks/Icon'
import { useSpring, animated } from 'react-spring'
import { useWasInViewport } from '@kaliber/use-is-in-viewport'
import { dayjs } from '/machinery/dayjs'

import cssColors from '/cssGlobal/color.css'
import userIcon from '/images/icons/user-bold.raw.svg'
import clockIcon from '/images/icons/clock-bold.raw.svg'
import calendarIcon from '/images/icons/calendar.raw.svg'
import flagIcon from '/images/icons/flag.raw.svg'

import styles from './TraineeshipCard.css'

export function TraineeshipCard({ title, intro, customPills, duration, startingSpots, openDate, startDate, slug, openCloseLabel, index, type }) {
  const language = useLanguage()
  const { ref, wasInViewport } = useWasInViewport({ rootMargin: '-10%' })
  const spring = useSpring({
    delay: 25 * index,
    opacity: wasInViewport ? 1 : 0,
    scale: wasInViewport ? 1 : 0.6,
    config: { mass: 0.5, tension: 600, friction: 50, precision: 0.01, velocity: 0 },
  })

  return (
    <animated.div style={spring} {...{ ref }} data-style-context='light' className={styles.component}>
      <div className={styles.top}>
        {type && <p className={styles.type}>{type}</p>}
        <Status statusValue={openCloseLabel?.label} />
      </div>
      <HeadingSm h={3} {...{ title }} />
      {Boolean(customPills?.length) && <Pills layoutClassName={styles.pillsLayout} pills={customPills} />}
      {intro && (
        <PortableTextCard layoutClassName={styles.introLayout} value={truncateText(intro[0])} />
      )}
      <JobInfo layoutClassName={styles.jobInfoLayout} {...{ openDate, startDate, startingSpots, duration }} />
      {slug && (<Link layoutClassName={styles.linkLayout} {...{ language, slug }} />)}
    </animated.div>
  )
}

function Status({ statusValue }) {
  const { __ } = useTranslate()
  const labelAndColor = {
    'soon-open': { label: __`soon-open`, color: cssColors.colorOrange300 },
    'closed': { label: __`closed`, color: cssColors.colorOrange700 },
    'soon-closed': { label: __`soon-closed`, color: cssColors.colorBlue300 },
    'open': { label: __`open`, color: cssColors.colorGreen700 }
  }

  return (
    <div className={styles.componentStatus}>
      {labelAndColor[statusValue]?.label || __`open`}
      <span
        className={styles.light}
        style={{ backgroundColor: labelAndColor[statusValue]?.color || cssColors.colorGreen700 }}
      />
    </div>
  )
}

function Pills({ pills, layoutClassName = undefined }) {
  return pills !== null && (
    <ul className={cx(styles.componentPills, layoutClassName)}>
      {pills?.slice(0, 3).map((x, i) => x?.property && (
        <li key={i} className={styles.pill}>{x?.property}</li>
      ))}
    </ul>
  )
}

function JobInfo({ layoutClassName, openDate, startDate, duration, startingSpots }) {
  const { __ } = useTranslate()

  return Boolean(openDate && startDate) && (
    <ul className={cx(styles.componentJobInfo, layoutClassName)}>
      {openDate && (
        <ListItem icon={calendarIcon} value={`${__`open-from`} ${formatDate(openDate)}`} />
      )}
      {startDate && (
        <ListItem icon={flagIcon} value={`${__`start-date`} ${formatDate(startDate)}`} />
      )}
      {duration && (
        <ListItem icon={clockIcon} value={`${duration} ${__`months`}`} />
      )}
      {startingSpots && (
        <ListItem icon={userIcon} value={`${startingSpots} ${__`starting-spots`}`} />
      )}
    </ul>
  )
}

function ListItem({ value, icon }) {
  return (
    <li className={styles.componentListItem}>
      <Icon {...{ icon }} />
      {value}
    </li>
  )
}

function Link({ language, slug, layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <a
      className={cx(styles.componentLink, layoutClassName)}
      aria-label={__`link-to-detail-traineeship`}
      data-x='goto-detail-traineeship'
      href={routeMap.app.traineeship.detail({ language, slug })}
    >
      <span className={styles.linkLabel}>{__`link-to-detail-traineeship`}</span>
    </a>
  )
}

function formatDate(date) {
  return dayjs(date).format('DD-MM-YYYY')
}

function truncateText(object) {
  const maxLength = 150
  const truncatedText = object.children[0].text.length > maxLength
    ? object.children[0].text.slice(0, maxLength - 3) + '...'
    : object.children[0].text

  return {
    ...object,
    children: object.children.map(child => ({
      ...child,
      text: truncatedText
    }))
  }
}
